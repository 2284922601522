import React, { Fragment } from "react"
import ReactPlayer from "react-player/file"

import "../styles/projectEmergingIndustries.scss"

import Contact from "../components/New-Contact-Form/new-contact-form"
import TestimonialBelt from "../components/Testimonial-Belt/testimonial-belt"

import { infoEmergingIndustriesProject } from "../constants/new-ghost-projects"

import EmergingIndustries from "../video/emerging-industries-final.mp4"

const ProjectEmergingIndustries = () => {
  return (
    <Fragment>
      <div className="project-emerging-industries-title">
        {/* <h1>Exploring Emerging Industries</h1> */}
      </div>
      <div className="project-emerging-industries-description">
        <p className="first-emerging-industries-paragraph">
          {infoEmergingIndustriesProject.longDescription1}
        </p>
        <p>{infoEmergingIndustriesProject.longDescription2}</p>
      </div>
      <div className="project-emerging-industries-main-container">
        <ReactPlayer
          url={EmergingIndustries}
          controls="true"
          width="100%"
          height="100%"
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
        />
      </div>
      <TestimonialBelt />
      <Contact />
    </Fragment>
  )
}

export default ProjectEmergingIndustries
